import { FC, useContext, useEffect, useState } from 'react';
import { ArticleContext, CurrentProjectInterface } from '../../constants/ArticleContext';
import { Form, Radio, Input, InputNumber, Button, Checkbox, Divider } from 'antd';
import { addDoc, updateDoc, doc } from 'firebase/firestore';
import { SaveOutlined } from '@ant-design/icons';

interface Props {
  drawerOpen: (open: boolean) => void;
  project?: CurrentProjectInterface;
}

const ProjectComposer: FC<Props> = ({ drawerOpen, project }) => {
  const { currentProjectCollectionRef, loadData } = useContext(ArticleContext);

  const [hold, setHold] = useState(project?.hold || false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, project]);

  const addItem = async (values: any) => {
    const fields = {
      active: values.visibility,
      name: values.name,
      description: values.description,
      image: values.image,
      progress: values.progress,
      hold: hold,
    };

    if (project) {
      const itemDoc = doc(currentProjectCollectionRef, project.id);
      await updateDoc(itemDoc, fields);
    } else {
      await addDoc(currentProjectCollectionRef, Object.assign(fields, { dateAdded: new Date() }));
    }

    form.resetFields();
    loadData();
    drawerOpen(false);
  };

  return (
    <Form form={form} autoComplete='off' onFinish={addItem}>
      <Form.Item label='Visibility' name='visibility' initialValue={project?.active || false}>
        <Radio.Group>
          <Radio value={true}>Active</Radio>
          <Radio value={false}>Hidden</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label='Name' name='name' initialValue={project?.name} required>
        <Input required />
      </Form.Item>

      <Form.Item label='Image' name='image' initialValue={project?.image} required>
        <Input required />
      </Form.Item>

      <Divider />

      <Checkbox value={hold} onChange={e => setHold(e.target.checked)}>
        Project on Hold
      </Checkbox>

      <Form.Item
        label='Description'
        name='description'
        initialValue={project?.description}
        required
      >
        <Input disabled={hold} required />
      </Form.Item>

      <Form.Item label='Progress' name='progress' initialValue={project?.progress} required>
        <InputNumber disabled={hold} min={0} max={100} required />
      </Form.Item>

      <Form.Item>
        <Button block type='primary' htmlType='submit' icon={<SaveOutlined />}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProjectComposer;
